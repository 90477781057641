import React, { useState, useEffect } from "react";
import { Modal, Button, Alert, InputGroup, FormControl } from "react-bootstrap";
import toast from "react-hot-toast";

const CheckoutModal = ({ show, handleClose, cartItems, userData }) => {
  const [timer, setTimer] = useState(1200);
  const [paymentLink, setPaymentLink] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [linkCopied, setLinkCopied] = useState(false);

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else if (timer === 0) {
      toast.error("Время для оплаты истекло!");
      handleClose();
    }
  }, [timer, handleClose]); // Убрали зависимость от show

  useEffect(() => {
    if (show && timer > 0) {
      const interval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else if (timer === 0) {
      toast.error("Время для оплаты истекло!");
      handleClose();
    }
  }, [show, timer, handleClose]); // Добавляем handleClose в зависимости

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  const handlePayment = async () => {
    if (paymentLink) {
      // Если ссылка уже сформирована, показываем её пользователю
      return;
    }

    try {
      const paymentData = {
        items: cartItems,
        user: userData,
        browserInfo: navigator.userAgent,
      };

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/yookassa`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(paymentData),
        }
      );

      const result = await response.json();
      if (response.ok) {
        setPaymentLink(result.paymentLink);
        setError(null);
        setSuccess("Ссылка на оплату успешно сформирована!");
        setLinkCopied(false); // Сбрасываем состояние копирования
      } else {
        throw new Error(result.error || "Не удалось создать платеж");
      }
    } catch (error) {
      setError(error.message);
      setSuccess(null);
    }
  };

  // Новая функция для копирования ссылки
  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(paymentLink).then(() => {
      setLinkCopied(true);
      toast.success("Ссылка скопирована в буфер обмена");
    });
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton className="border-0 pb-2">
        <Modal.Title>Оформление заказа</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && (
          <Alert variant="danger" className="mb-3">
            <strong>Ошибка: </strong> {error}
          </Alert>
        )}
        {success && (
          <Alert variant="success" className="mb-3">
            <strong>Успех: </strong> {success}
          </Alert>
        )}

        <div className="text-center mb-4">
          <p className="mb-1">Время для завершения оплаты:</p>
          <p
            style={{
              fontFamily: "monospace",
              fontSize: "2rem",
              fontWeight: "bold",
              letterSpacing: "2px",
            }}
          >
            {formatTime(timer)}
          </p>
        </div>

        {paymentLink ? (
          <>
            <InputGroup className="mb-3">
              <FormControl value={paymentLink} readOnly />
              <Button variant="outline-secondary" onClick={copyLinkToClipboard}>
                {linkCopied ? "Скопировано!" : "Копировать"}
              </Button>
            </InputGroup>
            <Button
              href={paymentLink}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-success btn-lg btn-block w-100 mb-3"
            >
              Перейти к оплате
            </Button>
          </>
        ) : (
          <Button
            onClick={handlePayment}
            className="btn btn-success btn-lg btn-block w-100 mb-3"
          >
            Сформировать ссылку для оплаты
          </Button>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default CheckoutModal;
