// ./src/components/Search.jsx
import React from "react";

const Search = ({ searchTerm, setSearchTerm, inputRef }) => {
  return (
    <input
      type="text"
      value={searchTerm}
      onChange={setSearchTerm} // Важно, что здесь используется onChange
      ref={inputRef}
      placeholder="Поиск товаров..."
    />
  );
};

export default Search;
