import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

const Navbar = () => {
  const state = useSelector((state) => state.handleCart);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);

  const checkAuthStatus = () => {
    const token = localStorage.getItem("auth_token");
    const userDataStr = localStorage.getItem("user_data");
    const isAuth = localStorage.getItem("is_authenticated") === "true";

    setIsAuthenticated(isAuth && !!token);
    if (userDataStr) {
      try {
        setUserData(JSON.parse(userDataStr));
      } catch (e) {
        console.error("Error parsing user data:", e);
      }
    }
  };

  // Проверяем статус авторизации при монтировании и изменении localStorage
  useEffect(() => {
    checkAuthStatus();

    // Слушаем изменения в авторизации
    window.addEventListener("auth-changed", checkAuthStatus);

    // Слушаем изменения в localStorage
    window.addEventListener("storage", checkAuthStatus);

    return () => {
      window.removeEventListener("auth-changed", checkAuthStatus);
      window.removeEventListener("storage", checkAuthStatus);
    };
  }, []);

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light py-3 sticky-top">
      <div className="container">
        <NavLink className="navbar-brand fw-bold fs-4 px-2" to="/">
          Digital shop
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav m-auto my-2 text-center">
            {/* ... остальные пункты меню ... */}
          </ul>
          <div className="buttons text-center">
            {isAuthenticated ? (
              <NavLink to="/dashboard" className="btn btn-outline-dark m-2">
                <i className="fa fa-user mr-1"></i> Личный кабинет
                {userData?.username && ` (${userData.username})`}
              </NavLink>
            ) : (
              <>
                <NavLink to="/login" className="btn btn-outline-dark m-2">
                  <i className="fa fa-sign-in-alt mr-1"></i> Вход
                </NavLink>
                <NavLink to="/register" className="btn btn-outline-dark m-2">
                  <i className="fa fa-user-plus mr-1"></i> Регистрация
                </NavLink>
              </>
            )}
            <NavLink to="/cart" className="btn btn-outline-dark m-2">
              <i className="fa fa-cart-shopping mr-1"></i> Корзина (
              {state.length})
            </NavLink>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
