// src/mockData2.js

export const products = [
  {
    id: 1,
    title: "Игровая консоль PlayStation 5",
    price: 49999,
    description:
      "Последнее поколение игровой консоли с мощным процессором и графикой. Поддержка 4K и быстрых загрузок.",
    category: "Игровые консоли",
    image:
      "https://avatars.mds.yandex.net/get-mpic/4785755/2a0000018e8115d448c613c16b7d5d4f4366/900x1200",
    rating: {
      rate: 4.8,
      count: 1500,
    },
  },
  {
    id: 2,
    title: "Игровая мышь Logitech G502",
    price: 3499,
    description:
      "Высокоточная игровая мышь с настраиваемыми кнопками и RGB-подсветкой.",
    category: "Игровые аксессуары",
    image:
      "https://avatars.mds.yandex.net/get-mpic/7631660/img_id9194141576544766392.jpeg/900x1200",
    rating: {
      rate: 4.6,
      count: 800,
    },
  },
  {
    id: 3,
    title: "Подписка на Steam",
    price: 1990,
    description:
      "Годовая подписка на платформу Steam с доступом к тысячам игр и эксклюзивным скидкам.",
    category: "Цифровые товары",
    image:
      "https://avatars.mds.yandex.net/i?id=a725c3d7937d8bf572932a9b23d80d5dbb32cb92-5598874-images-thumbs&n=13",
    rating: {
      rate: 4.9,
      count: 2000,
    },
  },
  {
    id: 4,
    title: "Игровая клавиатура Corsair K95",
    price: 9999,
    description:
      "Механическая клавиатура с подсветкой RGB и программируемыми клавишами.",
    category: "Игровые аксессуары",
    image:
      "https://avatars.mds.yandex.net/get-mpic/1704682/2a00000190c7bf65c922a31c6c419d8f482f/900x1200",
    rating: {
      rate: 4.7,
      count: 1200,
    },
  },
  {
    id: 5,
    title: "Наушники HyperX Cloud II",
    price: 7499,
    description:
      "Профессиональные игровые наушники с отличным звуком и комфортной посадкой.",
    category: "Игровые аксессуары",
    image:
      "https://api.alikson.ru/media/main/static/images/products/231559IOHFCZE510WylH9wcWQc.webp",
    rating: {
      rate: 4.8,
      count: 1800,
    },
  },
  {
    id: 6,
    title: "Игровая приставка Xbox Series X",
    price: 47999,
    description:
      "Мощная игровая консоль от Microsoft с поддержкой разрешения 4K и быстрым SSD.",
    category: "Игровые консоли",
    image:
      "https://avatars.mds.yandex.net/get-mpic/4011308/2a000001923d73058c8d15b08b7fe1c4cf83/402x536",
    rating: {
      rate: 4.7,
      count: 1300,
    },
  },
  {
    id: 7,
    title: "Игровой монитор ASUS ROG Swift",
    price: 21999,
    description:
      "27-дюймовый игровой монитор с частотой обновления 144Hz и временем отклика 1 мс.",
    category: "Игровые аксессуары",
    image:
      "https://avatars.mds.yandex.net/get-mpic/1602935/img_id3316350157960928596.png/900x1200",
    rating: {
      rate: 4.5,
      count: 900,
    },
  },
  {
    id: 8,
    title: "Электронный ключ активации игры Cyberpunk 2077",
    price: 2990,
    description:
      "Цифровой ключ для мгновенной загрузки и установки игры Cyberpunk 2077.",
    category: "Цифровые товары",
    image: "https://videoigr.net/images/Cyberpunc2077UExs.png",
    rating: {
      rate: 4.9,
      count: 2500,
    },
  },
  {
    id: 9,
    title: "Игровой контроллер Xbox Series X",
    price: 3999,
    description:
      "Беспроводной контроллер с эргономичным дизайном и долговечными батареями.",
    category: "Игровые аксессуары",
    image:
      "https://portagame.ru///katalog/game/xbox-series-accessories/gamepad-xbox-series-forza5.jpg",
    rating: {
      rate: 4.6,
      count: 1100,
    },
  },
  {
    id: 10,
    title: "Подписка на Origin Access Premier",
    price: 2990,
    description:
      "Доступ к эксклюзивным играм и скидкам на платформе Origin на протяжении года.",
    category: "Цифровые товары",
    image:
      "https://media.contentapi.ea.com/content/dam/walrus/common/ea-grid-tile-oapremier-blog-1280x720.png.adapt.crop191x100.628p.png",
    rating: {
      rate: 4.7,
      count: 1600,
    },
  },
  // Добавьте больше товаров по аналогии...
];

export const cartItems = [
  // Другие товары в корзине...
];
