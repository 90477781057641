// ./src/pages/Register.jsx
import React from "react";
import { Footer, Navbar } from "../components";
import { Link } from "react-router-dom";

const Register = () => {
  return (
    <>
      <Navbar />
      <div className="container my-3 py-3">
        <h1 className="text-center">Регистрация</h1>
        <hr />
        <div className="row my-4 h-100">
          <div className="col-md-4 col-lg-4 col-sm-8 mx-auto">
            <form>
              <div className="form my-3">
                <label htmlFor="Name">Полное имя</label>
                <input
                  type="text"
                  className="form-control"
                  id="Name"
                  placeholder="Введите ваше имя"
                />
              </div>
              <div className="form my-3">
                <label htmlFor="Email">Электронная почта</label>
                <input
                  type="email"
                  className="form-control"
                  id="Email"
                  placeholder="name@example.com"
                />
              </div>
              <div className="form my-3">
                <label htmlFor="Password">Пароль</label>
                <input
                  type="password"
                  className="form-control"
                  id="Password"
                  placeholder="Пароль"
                />
              </div>
              <div className="my-3">
                <p>
                  Уже есть аккаунт?{" "}
                  <Link
                    to="/login"
                    className="text-decoration-underline text-info"
                  >
                    Войти
                  </Link>{" "}
                </p>
              </div>
              <div className="text-center">
                <button
                  className="my-2 mx-auto btn btn-dark"
                  type="submit"
                  disabled
                >
                  Зарегистрироваться
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Register;
