import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

const FloatingCart = () => {
  const state = useSelector((state) => state.handleCart);
  const [showCart, setShowCart] = useState(false);
  const [prevCount, setPrevCount] = useState(0);
  const itemCount = state.reduce((total, item) => total + item.qty, 0);

  useEffect(() => {
    if (state.length > 0 && !showCart) {
      // Добавляем небольшую задержку перед показом корзины
      setTimeout(() => setShowCart(true), 50);
    } else if (state.length === 0 && showCart) {
      setShowCart(false);
    }
    setPrevCount(itemCount);
  }, [state, showCart, itemCount]);

  return (
    <CSSTransition
      in={showCart}
      timeout={300}
      classNames="float"
      unmountOnExit
      nodeRef={React.useRef(null)} // Добавьте эту строку
    >
      <Link to="/cart" className="floating-cart" ref={React.useRef(null)}>
        <div className="floating-cart-icon">
          <i className="fa fa-shopping-cart"></i>
          <CSSTransition
            in={prevCount !== itemCount}
            timeout={300}
            classNames="pop"
            onEntered={() => setPrevCount(itemCount)}
            nodeRef={React.useRef(null)} // И эту строку
          >
            <span className="floating-cart-count" ref={React.useRef(null)}>
              {itemCount}
            </span>
          </CSSTransition>
        </div>
      </Link>
    </CSSTransition>
  );
};

export default FloatingCart;
