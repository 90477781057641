import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Footer, Navbar } from "../components";
import { apiClient } from "../utils/apiClient";
import styles from "./Login.module.css";
import toast from "react-hot-toast";

const Login = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [initialCheckDone, setInitialCheckDone] = useState(false);

  // Единоразовая проверка авторизации при монтировании
  useEffect(() => {
    const checkInitialAuth = async () => {
      if (localStorage.getItem("is_authenticated")) {
        navigate("/dashboard", { replace: true });
      }
      setInitialCheckDone(true);
    };

    checkInitialAuth();
  }, [navigate]);

  const onTelegramAuth = async (user) => {
    if (isLoading) return;

    setIsLoading(true);
    try {
      const response = await apiClient.telegramAuth(user);

      if (response.success) {
        toast.success("Successfully logged in!");
        // Используем replace: true для предотвращения возврата на страницу логина
        navigate("/dashboard", { replace: true });
      } else {
        throw new Error(response.error || "Authentication failed");
      }
    } catch (error) {
      console.error("Authentication Error:", error);
      toast.error(error.message || "Failed to authenticate");
      localStorage.clear();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!initialCheckDone) return;

    const script = document.createElement("script");
    script.src = "https://telegram.org/js/telegram-widget.js?22";
    script.async = true;
    script.setAttribute("data-telegram-login", "AutoGPTsubscription_bot");
    script.setAttribute("data-size", "large");
    script.setAttribute("data-onauth", "onTelegramAuth(user)");
    script.setAttribute("data-request-access", "write");

    window.onTelegramAuth = onTelegramAuth;

    const telegramLoginDiv = document.getElementById("telegram-login");
    if (telegramLoginDiv && !telegramLoginDiv.hasChildNodes()) {
      telegramLoginDiv.appendChild(script);
    }

    return () => {
      if (telegramLoginDiv && telegramLoginDiv.hasChildNodes()) {
        telegramLoginDiv.innerHTML = "";
      }
      delete window.onTelegramAuth;
    };
  }, [initialCheckDone]);

  if (!initialCheckDone) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Navbar />
      <div className={`container ${styles.container} my-5`}>
        <div className="row justify-content-center">
          <div className="col-md-6 text-center">
            <h1>Вход</h1>
            <p>
              Войдите через Telegram, чтобы получить доступ к вашему личному
              кабинету.
            </p>

            {isLoading ? (
              <div className="d-flex justify-content-center my-4">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <div
                id="telegram-login"
                className={`py-4 ${styles.telegramLogin}`}
              />
            )}

            <p>
              Нет аккаунта? <Link to="/register">Зарегистрируйтесь здесь</Link>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Login;
