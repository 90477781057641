// src/utils/iconUtils.js

// Константа с количеством доступных иконок
const TOTAL_ICONS = 15; // Измените это значение в соответствии с реальным количеством иконок

/**
 * Генерирует путь к случайной иконке профиля
 * @returns {string} Путь к случайной иконке
 */
export const getRandomProfileIcon = () => {
  const randomNumber = Math.floor(Math.random() * TOTAL_ICONS) + 1;
  return `/iconsets/icon${randomNumber}.png`;
};

/**
 * Проверяет существование иконки по указанному пути
 * @param {string} iconPath Путь к иконке
 * @returns {Promise<boolean>} Промис с результатом проверки
 */
export const checkIconExists = async (iconPath) => {
  try {
    const response = await fetch(iconPath);
    return response.ok;
  } catch (error) {
    console.error('Error checking icon:', error);
    return false;
  }
};

/**
 * Получает валидную иконку профиля
 * @param {string} defaultIcon Путь к иконке по умолчанию
 * @returns {Promise<string>} Промис с путем к валидной иконке
 */
export const getValidProfileIcon = async (defaultIcon = '/iconsets/icon1.png') => {
  const randomIcon = getRandomProfileIcon();
  const exists = await checkIconExists(randomIcon);
  return exists ? randomIcon : defaultIcon;
};