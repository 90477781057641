// src/utils/apiClient.js
import { getAuthToken, setAuthToken, removeAuthToken } from "./jwtUtils";

class ApiClient {
  // Добавляем флаг для предотвращения множественных проверок
  static isCheckingAuth = false;

  constructor() {
    this.baseUrl =
      process.env.REACT_APP_BACKEND_URL ||
      "https://shop.minicoder.ru/server/api";
    this.requestCount = 0;
    this.rateLimitLimit = Infinity;
    this.rateLimitRemaining = Infinity;
    this.rateLimitReset = null;
  }

  // Утилита для преобразования userId в строку
  ensureStringId(userId) {
    return userId?.toString() || "";
  }

  // Обновление информации о rate limiting
  updateRateLimits(headers) {
    this.rateLimitLimit = parseInt(
      headers.get("X-RateLimit-Limit") || Infinity
    );
    this.rateLimitRemaining = parseInt(
      headers.get("X-RateLimit-Remaining") || Infinity
    );
    this.rateLimitReset = headers.get("X-RateLimit-Reset");

    // Логируем информацию о лимитах
    console.info("Rate Limits:", {
      limit: this.rateLimitLimit,
      remaining: this.rateLimitRemaining,
      reset: this.rateLimitReset,
    });
  }

  async request(endpoint, options = {}) {
    const url = `${this.baseUrl}${endpoint}`;
    const token = getAuthToken();

    const defaultOptions = {
      headers: {
        "Content-Type": "application/json",
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      credentials: "include",
    };

    const fetchOptions = {
      ...defaultOptions,
      ...options,
      headers: {
        ...defaultOptions.headers,
        ...options.headers,
      },
    };

    console.group(`🌐 API Request: ${options.method || "GET"} ${endpoint}`);
    console.log("Request Options:", fetchOptions);

    try {
      const response = await fetch(url, fetchOptions);
      this.updateRateLimits(response.headers);

      const data = await response.json();
      console.log("Response:", data);

      if (!response.ok) {
        // Специальная обработка ошибок аутентификации
        if (response.status === 401) {
          removeAuthToken();
          window.location.href = "/login";
          throw new Error("Authentication required");
        }
        throw new Error(data.error || "API Error");
      }

      console.groupEnd();
      return data;
    } catch (error) {
      console.error("Request failed:", error);
      console.groupEnd();
      throw error;
    }
  }

  // Метод получения транзакций пользователя
  async getUserTransactions(userId) {
    try {
      if (!userId) {
        throw new Error("User ID is required");
      }

      const stringUserId = this.ensureStringId(userId);
      const response = await this.request(
        `/users/${stringUserId}/transactions`
      );

      if (!response.success) {
        throw new Error(response.error || "Failed to fetch transactions");
      }

      return {
        success: true,
        transactions: response.transactions || [],
      };
    } catch (error) {
      console.error("Error fetching transactions:", error);
      return {
        success: false,
        transactions: [],
        error: error.message,
      };
    }
  }

  // Метод получения покупок пользователя
  async getUserPurchases(userId) {
    try {
      if (!userId) {
        throw new Error("User ID is required");
      }

      const stringUserId = this.ensureStringId(userId);
      const response = await this.request(`/users/${stringUserId}/purchases`);

      if (!response.success) {
        throw new Error(response.error || "Failed to fetch purchases");
      }

      return {
        success: true,
        purchases: response.purchases || [],
      };
    } catch (error) {
      console.error("Error fetching purchases:", error);
      return {
        success: false,
        purchases: [],
        error: error.message,
      };
    }
  }

  async telegramAuth(userData) {
    try {
      const response = await this.request("/telegram-auth", {
        method: "POST",
        body: JSON.stringify(userData),
      });

      if (response.success && response.auth?.token) {
        // Сохраняем данные пользователя
        this.saveAuthData(
          response.auth.token,
          response.user,
          response.auth.expiresIn
        );

        // Вызываем событие авторизации
        window.dispatchEvent(new Event("auth-changed"));
        return response;
      }

      throw new Error(response.error || "Authentication failed");
    } catch (error) {
      console.error("Telegram auth failed:", error);
      throw error;
    }
  }

  // Новый метод для сохранения данных авторизации
  saveAuthData(token, user, expiresIn) {
    localStorage.setItem("auth_token", token);
    localStorage.setItem("user_data", JSON.stringify(user));
    localStorage.setItem("is_authenticated", "true");

    const expiresAt = Date.now() + expiresIn * 1000;
    localStorage.setItem("token_expires_at", expiresAt.toString());
  }

  async testAuth() {
    try {
      const response = await this.request("/test/auth");
      return response.success === true;
    } catch (error) {
      console.error("Auth check failed:", error);
      return false;
    }
  }

  // Добавляем метод для выхода
  logout() {
    localStorage.clear();
    window.dispatchEvent(new Event("auth-changed"));
  }

  // Вспомогательный метод для обработки ошибок
  handleError(error) {
    console.error("API Error:", error);
    if (error.status === 401) {
      this.logout();
      return {
        success: false,
        error: "Authentication required",
      };
    }
    return {
      success: false,
      error: error.message || "Unknown error occurred",
    };
  }
}

// Создаем и экспортируем единственный экземпляр
export const apiClient = new ApiClient();
