// ./src/pages/Cart.jsx
import React, { useState } from "react";
import { Footer, Navbar } from "../components";
import { useSelector, useDispatch } from "react-redux";
import { addCart, delCart } from "../redux/action";
import { Link } from "react-router-dom";
import ProductModal from "../components/ProductModal";
import toast from "react-hot-toast";
import CheckoutModal from "../components/CheckoutModal";

const Cart = () => {
  const state = useSelector((state) => state.handleCart);
  const dispatch = useDispatch();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showCheckout, setShowCheckout] = useState(false);
  const cartItems = useSelector((state) => state.handleCart);
  const userData = useSelector((state) => state.userData); // Информация о пользователе

  const EmptyCart = () => {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12 py-5 bg-light text-center">
            <h4 className="p-3 display-5">Ваша корзина пуста</h4>
            <Link to="/" className="btn btn-outline-dark mx-4">
              <i className="fa fa-arrow-left"></i> Продолжить покупки
            </Link>
          </div>
        </div>
      </div>
    );
  };

  const addItem = (product) => {
    const currentItemInCart = cartItems.find((item) => item.id === product.id);
    const currentQuantity = currentItemInCart ? currentItemInCart.qty : 0;

    if (currentQuantity < product.stock) {
      dispatch(addCart(product));
      toast.success("Добавлено в корзину");
    } else {
      toast.error("Достигнут максимальный доступный остаток");
    }
  };

  const removeItem = (product) => {
    dispatch(delCart(product));
  };

  const handleShowModal = (product) => {
    setSelectedProduct(product);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleCheckout = () => {
    setShowCheckout(true);
  };

  const handleCloseCheckout = () => setShowCheckout(false);

  const ShowCart = () => {
    let subtotal = 0;
    let shipping = 0.0;
    let totalItems = 0;
    state.forEach((item) => {
      subtotal += item.price * item.qty;
      totalItems += item.qty;
    });
    return (
      <>
        <section className="h-100 gradient-custom">
          <div className="container py-5">
            <div className="row d-flex justify-content-center my-4">
              <div className="col-md-8">
                <div className="card mb-4">
                  <div className="card-header py-3">
                    <h5 className="mb-0">Список товаров</h5>
                  </div>
                  <div className="card-body">
                    {state.map((item) => {
                      return (
                        <div
                          key={item.id}
                          className="cart-item"
                          onClick={() => handleShowModal(item)}
                        >
                          <div className="row d-flex align-items-center">
                            <div className="col-lg-3 col-md-12">
                              <div
                                className="bg-image rounded"
                                data-mdb-ripple-color="light"
                              >
                                <img
                                  src={item.image}
                                  alt={item.title}
                                  className="img-fluid"
                                />
                              </div>
                            </div>

                            <div className="col-lg-5 col-md-6">
                              <p className="lead fw-normal mb-2">
                                <strong>{item.title}</strong>
                              </p>
                              <p>
                                <span className="text-muted">Цена: </span>
                                {item.price} ₽
                              </p>
                            </div>

                            <div className="col-lg-4 col-md-6 d-flex justify-content-between align-items-center">
                              <div className="d-flex">
                                <button
                                  className="btn btn-link px-2"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeItem(item);
                                  }}
                                >
                                  <i className="fas fa-minus"></i>
                                </button>

                                <input
                                  type="number"
                                  className="form-control form-control-sm"
                                  value={item.qty}
                                  readOnly
                                  style={{ width: "50px" }}
                                />

                                <button
                                  className="btn btn-link px-2"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    addItem(item);
                                  }}
                                >
                                  <i className="fas fa-plus"></i>
                                </button>
                              </div>

                              <p className="text-start text-md-center">
                                <strong>{item.qty * item.price} ₽</strong>
                              </p>
                            </div>
                          </div>
                          <hr className="my-4" />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card mb-4">
                  <div className="card-header py-3 bg-light">
                    <h5 className="mb-0">Сводка заказа</h5>
                  </div>
                  <div className="card-body">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                        Товары ({totalItems})
                        <span>{Math.round(subtotal)} ₽</span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                        Доставка
                        <span>{shipping} ₽</span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-3">
                        <div>
                          <strong>Итого</strong>
                        </div>
                        <span>
                          <strong>{Math.round(subtotal + shipping)} ₽</strong>
                        </span>
                      </li>
                    </ul>

                    <button
                      onClick={handleCheckout}
                      className="btn btn-dark btn-lg btn-block w-100"
                    >
                      Оформить заказ
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };

  return (
    <>
      <Navbar />
      <div className="container my-3 py-3">
        <h1 className="text-center">Корзина</h1>
        <hr />
        {state.length > 0 ? <ShowCart /> : <EmptyCart />}
      </div>
      <Footer />
      <ProductModal
        show={showModal}
        handleClose={handleCloseModal}
        product={selectedProduct}
      />
      <CheckoutModal
        show={showCheckout}
        handleClose={handleCloseCheckout}
        cartItems={cartItems}
        userData={userData}
      />
    </>
  );
};

export default Cart;
