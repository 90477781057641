import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addCart } from "../redux/action";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

const ProductModal = ({ show, handleClose, product }) => {
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.handleCart);

  const handleAddToCart = () => {
    const currentItemInCart = cartItems.find((item) => item.id === product.id);
    const currentQuantity = currentItemInCart ? currentItemInCart.qty : 0;

    if (currentQuantity < product.stock) {
      dispatch(addCart(product));
      toast.success(`${product.title} добавлен в корзину`);
    } else {
      toast.error("Достигнут максимальный доступный остаток");
    }
  };

  if (!product) return null;

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <div className="modal-content rounded-3 shadow">
        <Modal.Header closeButton className="border-bottom-0">
          <Modal.Title className="fw-bold fs-3">{product.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-5">
          <div className="row">
            <div className="col-md-6 d-flex justify-content-center">
              <img
                src={product.image}
                alt={product.title}
                className="img-fluid rounded-3 shadow-sm"
                style={{ maxHeight: "300px", objectFit: "cover" }}
              />
            </div>
            <div className="col-md-6">
              <h4 className="fw-bold mb-4">Описание</h4>
              <p className="text-muted">{product.description}</p>
              <h5 className="fw-bold">Категория: {product.category}</h5>
              <h5 className="fw-bold text-success">Цена: {product.price} ₽</h5>
              <h5 className="fw-bold">
                Рейтинг: {product.rating.rate}{" "}
                <i className="fa fa-star text-warning"></i> (
                {product.rating.count})
              </h5>
              <h5 className="fw-bold">В наличии: {product.stock} шт.</h5>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-top-0 d-flex justify-content-between w-100">
          <Link
            to={"/product/" + product.id}
            className="btn btn-dark btn-lg rounded-3 flex-grow-1 me-2"
          >
            Купить
          </Link>
          <Button
            variant="primary"
            onClick={handleAddToCart}
            className="btn-lg rounded-3 flex-grow-1"
            disabled={product.stock === 0}
          >
            {product.stock > 0 ? "Добавить в корзину" : "Нет в наличии"}
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default ProductModal;
