// src/pages/Dashboard.jsx
import React, { useState, useEffect } from "react";
import { Footer, Navbar } from "../components";
import styles from "./Dashboard.module.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { format } from "date-fns";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
// src/pages/Dashboard.jsx - обновленная часть с API вызовами
import { apiRequest, getAuthToken } from "../utils/jwtUtils";
import { apiClient } from "../utils/apiClient";
import { getValidProfileIcon } from "../utils/iconUtils";

const Dashboard = () => {
  const [user, setUser] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [purchasedProducts, setPurchasedProducts] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();
  // Добавляем новое состояние для отслеживания загрузки
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [profileIcon, setProfileIcon] = useState(user?.photo_url || null);

  useEffect(() => {
    const loadProfileIcon = async () => {
      if (!user?.photo_url) {
        const iconPath = await getValidProfileIcon();
        setProfileIcon(iconPath);
      }
    };
    
    loadProfileIcon();
  }, [user]);

  // Обновляем функцию форматирования даты
  const formatDate = (dateString) => {
    try {
      // Проверяем, что у нас валидная строка даты
      if (!dateString) return "N/A";

      const date = new Date(dateString);
      // Проверяем, что дата валидна
      if (isNaN(date.getTime())) return "Invalid Date";

      // Форматируем дату с учетом локального времени
      return format(date, "dd.MM.yyyy HH:mm", {
        timeZone: "UTC", // Используем UTC чтобы избежать проблем с часовыми поясами
      });
    } catch (error) {
      console.error("Error formatting date:", error);
      return "Error";
    }
  };

  // Обработчики модального окна
  const handleOpenDialog = (item) => {
    setSelectedItem(item);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedItem(null);
  };

  // Функция логирования
  const logDebug = (message, data = null) => {
    const timestamp = new Date().toISOString();
    console.log(`[${timestamp}] ${message}`);
    if (data) {
      console.log("Data:", data);
    }
  };

  // Добавляем в начало компонента Dashboard
  useEffect(() => {
    const checkAuth = async () => {
      if (!localStorage.getItem("is_authenticated")) {
        navigate("/login", { replace: true });
        return;
      }

      try {
        const isValid = await apiClient.testAuth();
        if (!isValid) {
          throw new Error("Invalid authentication");
        }
      } catch (error) {
        console.error("Auth check failed:", error);
        localStorage.clear();
        navigate("/login", { replace: true });
      }
      
    };

    checkAuth();
  }, [navigate]);

  useEffect(() => {
    const loadUserData = async () => {
      try {
        // Получаем данные пользователя из localStorage
        const userDataStr = localStorage.getItem("user_data");
        if (!userDataStr) {
          throw new Error("No user data found");
        }

        const userData = JSON.parse(userDataStr);
        setUser(userData); // Устанавливаем данные пользователя

        // Загружаем транзакции и покупки
        const [transactionsData, purchasesData] = await Promise.all([
          apiClient.getUserTransactions(userData.id),
          apiClient.getUserPurchases(userData.id),
        ]);

        setTransactions(transactionsData.transactions || []);
        setPurchasedProducts(purchasesData.purchases || []);
        setError(null);
      } catch (err) {
        console.error("Error loading dashboard data:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadUserData();
  }, []);

  // Обновляем функцию handleLogout
  const handleLogout = () => {
    localStorage.clear();
    toast.success("Выход выполнен успешно");
    navigate("/login", { replace: true });
  };

  const handleCopyKey = (key) => {
    navigator.clipboard.writeText(key).then(() => {
      logDebug("Key copied to clipboard");
      toast.success("Ключ скопирован");
    });
  };

  // Обновляем условие рендеринга для загрузки
  if (loading) {
    return (
      <>
        <Navbar />
        <div className={styles.container}>
          <div className="text-center py-5">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Загрузка...</span>
            </div>
            <p className="mt-3">Загрузка данных...</p>
          </div>
        </div>
        <Footer />
      </>
    );
  }
  // Обновляем условие для ошибки
  if (error) {
    return (
      <>
        <Navbar />
        <div className={styles.container}>
          <div className="alert alert-danger" role="alert">
            Ошибка загрузки данных: {error}
          </div>
        </div>
        <Footer />
      </>
    );
  }
  // Обновляем проверку наличия данных пользователя
  if (!user) {
    return (
      <>
        <Navbar />
        <div className={styles.container}>
          <div className="alert alert-warning" role="alert">
            Данные пользователя не найдены. Попробуйте выйти и войти снова.
          </div>
          <button className="btn btn-outline-primary" onClick={handleLogout}>
            Выйти
          </button>
        </div>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Navbar />

      <div className={styles.container}>
        <h2 className="mb-4">Личный кабинет</h2>
        <button className="btn btn-outline-danger" onClick={handleLogout}>
          Выйти
        </button>

        {/* Блок с данными пользователя */}
        <div className={styles.userCardContainer}>
          <div className={`${styles.card} ${styles.userCard}`}>
            <div className={styles["card-header"]}>Данные пользователя</div>
            <div
              className={`${styles["card-body"]} ${styles["user-info-center"]}`}
            >
              <img
  src={profileIcon || user.photo_url || '/iconsets/icon1.png'}
  alt={`${user.first_name} ${user.last_name}`}
  className={styles["user-photo"]}
  onError={async (e) => {
    // Если загрузка иконки не удалась, пробуем загрузить случайную
    const newIcon = await getValidProfileIcon();
    setProfileIcon(newIcon);
  }}
/>
              <h5 className="mt-3 mb-1">
                {user.first_name} {user.last_name}
              </h5>
              <p className="mb-1">ID: {user.id}</p>
              {user.username && (
                <p className="mb-0">Username: @{user.username}</p>
              )}
            </div>
          </div>
        </div>

        {/* Блок с историей транзакций */}
        <div className={styles.card}>
          <div className={styles["card-header"]}>История транзакций</div>
          <div className={styles["card-body"]}>
            {transactions.length === 0 ? (
              <p>Нет транзакций.</p>
            ) : (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <strong>ID</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Дата</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Сумма</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Товар</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Действия</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {transactions.map((tx) => (
                      <TableRow key={tx.id}>
                        <TableCell>{tx.id}</TableCell>
                        <TableCell>{formatDate(tx.date)}</TableCell>
                        <TableCell>{tx.amount}</TableCell>
                        <TableCell>{tx.product}</TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => handleOpenDialog(tx)}
                          >
                            Просмотр
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </div>

        {/* Блок с купленными товарами и сервисными ключами */}
        <div className={styles.card}>
          <div className={styles["card-header"]}>Купленные товары</div>
          <div className={styles["card-body"]}>
            {purchasedProducts.length === 0 ? (
              <p>Нет купленных товаров.</p>
            ) : (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <strong>Название</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Сервисный ключ</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Действия</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {purchasedProducts.map((product) => (
                      <TableRow key={product.id}>
                        <TableCell>{product.title}</TableCell>
                        <TableCell>
                          <div className={styles.keyContainer}>
                            <span className={styles["product-key"]}>
                              {product.key}
                            </span>
                            <Tooltip title="Копировать ключ">
                              <IconButton
                                onClick={() => handleCopyKey(product.key)}
                                size="small"
                              >
                                <ContentCopyIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => handleOpenDialog(product)}
                          >
                            Просмотр
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </div>
      </div>

      {/* Модальное окно для просмотра деталей */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {selectedItem && selectedItem.product
            ? "Детали транзакции"
            : "Детали продукта"}
        </DialogTitle>
        <DialogContent>
          {selectedItem && (
            <div>
              {selectedItem.product ? (
                // Для транзакций
                <>
                  <p>
                    <strong>ID:</strong> {selectedItem.id}
                  </p>
                  <p>
                    <strong>Дата:</strong> {formatDate(selectedItem.date)}
                  </p>
                  <p>
                    <strong>Сумма:</strong> {selectedItem.amount}
                  </p>
                  <p>
                    <strong>Товар:</strong> {selectedItem.product}
                  </p>
                  <p>
                    <strong>Детали:</strong> {selectedItem.details}
                  </p>
                </>
              ) : (
                // Для продуктов
                <>
                  <p>
                    <strong>Название:</strong> {selectedItem.title}
                  </p>
                  <p>
                    <strong>Сервисный ключ:</strong> {selectedItem.key}
                  </p>
                </>
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>

      <Footer />
    </>
  );
};

export default Dashboard;
