import React from "react";
import FloatingCart from "./FloatingCart"; // Добавлено: импорт FloatingCart

const Footer = () => {
  return (
    <>
      <footer className="mb-0 text-center">
        <div className="d-flex align-items-center justify-content-center pb-5">
          <div className="col-md-6">
            <p className="mb-3 mb-md-0">
              Сделано с ❤️ с помощью{" "}
              <a
                href="https://github.com/heAdz0r"
                className="text-decoration-underline text-dark fs-5"
                target="_blank"
                rel="noreferrer"
              >
                heAdz0r
              </a>
            </p>
            <a
              className="text-dark fs-4"
              href="https://github.com/heAdz0r"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-github"></i>
            </a>
          </div>
        </div>
      </footer>
      <FloatingCart /> {/* Добавлено: компонент FloatingCart */}
    </>
  );
};

export default Footer;
