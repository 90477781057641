// src/utils/jwtUtils.js

// Константы для работы с JWT
export const JWT_TOKEN_KEY = "auth_token";

// Утилита для логирования запросов
export const logApiRequest = (method, url, data = null) => {
  console.group(`🚀 API Request: ${method} ${url}`);
  if (data) {
    console.log("Request Data:", data);
  }
  console.groupEnd();
};

// Утилита для логирования ответов
export const logApiResponse = (method, url, response) => {
  console.group(`📥 API Response: ${method} ${url}`);
  console.log("Status:", response.status);
  console.log("Data:", response);
  console.groupEnd();
};

// Функции для работы с JWT
export const setAuthToken = (token) => {
  if (token) {
    localStorage.setItem(JWT_TOKEN_KEY, token);
    return true;
  }
  return false;
};

export const getAuthToken = () => {
  return localStorage.getItem(JWT_TOKEN_KEY);
};

export const removeAuthToken = () => {
  localStorage.removeItem(JWT_TOKEN_KEY);
};

// Утилита для добавления токена к заголовкам
export const getAuthHeaders = () => {
  const token = getAuthToken();
  return token ? { Authorization: `Bearer ${token}` } : {};
};

// Универсальная функция для API запросов с логированием
export const apiRequest = async (method, url, data = null) => {
  const headers = {
    "Content-Type": "application/json",
    ...getAuthHeaders(),
  };

  const config = {
    method,
    headers,
    credentials: "include",
  };

  if (data) {
    config.body = JSON.stringify(data);
  }

  logApiRequest(method, url, data);

  try {
    const response = await fetch(url, config);
    const responseData = await response.json();
    logApiResponse(method, url, responseData);

    if (!response.ok) {
      throw new Error(responseData.message || "API Error");
    }

    return responseData;
  } catch (error) {
    console.error(`🚨 API Error: ${method} ${url}`, error);
    throw error;
  }
};
