// src/components/ProtectedRoute.jsx
import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { apiClient } from "../utils/apiClient";
import { Navbar, Footer } from "../components";

export const LoadingState = () => (
  <>
    <Navbar />
    <div className="container">
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "60vh" }}
      >
        <div className="text-center">
          <div className="spinner-border text-primary mb-3" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <p className="lead">Загрузка данных...</p>
        </div>
      </div>
    </div>
    <Footer />
  </>
);

const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const token = localStorage.getItem("auth_token");
        const userData = localStorage.getItem("user_data");

        if (!token || !userData) {
          setIsAuthenticated(false);
          return;
        }

        const isValid = await apiClient.testAuth();
        setIsAuthenticated(isValid);

        if (!isValid) {
          localStorage.clear();
        }
      } catch (error) {
        console.error("Auth check failed:", error);
        setIsAuthenticated(false);
        localStorage.clear();
      }
    };

    checkAuth();
  }, []);

  if (isAuthenticated === null) {
    return <LoadingState />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoute;
