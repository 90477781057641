//components/Products.jsx
import React, { useState, useEffect, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import { addCart } from "../redux/action";
import Skeleton from "react-loading-skeleton"; // Импорт skeleton для загрузки

import "react-loading-skeleton/dist/skeleton.css";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";

import Search from "./Search.jsx"; // Импортируем компонент поиска
import ProductModal from "./ProductModal"; // Импортируем модальное окно

import { io } from "socket.io-client";

import "./Products.css"; // Импортируем стили карточек продуктов

const Products = () => {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const searchInputRef = useRef(null); // Реф для поиска

  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.handleCart);

  const addProduct = (product) => {
    const currentItemInCart = cartItems.find((item) => item.id === product.id);
    const currentQuantity = currentItemInCart ? currentItemInCart.qty : 0;

    if (currentQuantity < product.stock) {
      dispatch(addCart(product));
      toast.success("Добавлено в корзину");
    } else {
      toast.error("Достигнут максимальный доступный остаток");
    }
  };

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = (product) => {
    setSelectedProduct(product);
    setShowModal(true);
  };

  useEffect(() => {
    const wsUrl = `wss://shop.minicoder.ru/server/socket.io`;
    const ws = new WebSocket(wsUrl);

    ws.onopen = () => {
      console.log("Connected to WebSocket server");
    };

    ws.onmessage = (event) => {
      try {
        const products = JSON.parse(event.data);
        console.log("Received products update:", products);

        if (Array.isArray(products)) {
          setData(products);
          setFilter(products);
          setLoading(false);
          toast.success("Продукты обновлены!");
        } else {
          console.error("Invalid data format received");
          setError("Некорректный формат данных");
          setLoading(false);
          toast.error("Ошибка при обновлении продуктов");
        }
      } catch (error) {
        console.error("Error processing WebSocket message:", error);
        setError("Ошибка обработки данных");
        setLoading(false);
        toast.error("Ошибка при обработке данных");
      }
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
      setError("Ошибка WebSocket соединения");
      setLoading(false);
      toast.error("Ошибка соединения с сервером");
    };

    ws.onclose = () => {
      console.log("WebSocket connection closed");
      // Можно добавить логику переподключения здесь
    };

    // Cleanup на unmount
    return () => {
      if (ws.readyState === WebSocket.OPEN) {
        ws.close();
      }
    };
  }, []); // Пустой массив зависимостей для однократного выполнения

  const handleSearchChange = (e) => {
    const value = e.target?.value || "";
    setSearchTerm(value);
    if (value === "") {
      setFilter(data);
    } else {
      const lowercasedFilter = value.toLowerCase();
      const filteredData = data.filter(
        (item) =>
          item.title.toLowerCase().includes(lowercasedFilter) ||
          item.description.toLowerCase().includes(lowercasedFilter)
      );
      setFilter(filteredData);
    }
  };

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus(); // Устанавливаем фокус на поле поиска
    }
  }, [searchTerm]);

  const Loading = () => {
    return (
      <>
        <div className="col-12 py-5 text-center">
          <Skeleton height={40} width={560} />
        </div>
        {[...Array(6)].map((_, index) => (
          <div key={index} className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
            <Skeleton height={592} />
          </div>
        ))}
      </>
    );
  };

  // В функцию filterProduct добавляем проверку на наличие продуктов
  const filterProduct = (category) => {
    if (category === "Все") {
      setFilter(data); // Показываем все товары, если выбрана категория "Все"
    } else {
      const updatedList = data.filter((item) => item.category === category);
      setFilter(updatedList.length ? updatedList : []); // Если список пустой, устанавливаем пустой массив
    }
  };

  // В компонент ShowProducts изменяем условие рендера для отсутствующих продуктов
  const ShowProducts = () => {
    if (error) {
      return <div className="error-message">{error}</div>;
    }

    return (
      <>
        {/* Компонент поиска */}
        <Search
          searchTerm={searchTerm}
          setSearchTerm={handleSearchChange} // Передаем функцию для обработки изменений поиска
          inputRef={searchInputRef}
        />

        <div className="buttons text-center py-5">
          <button
            className="btn btn-outline-dark btn-sm m-2"
            onClick={() => filterProduct("Все")}
          >
            Все
          </button>
          <button
            className="btn btn-outline-dark btn-sm m-2"
            onClick={() => filterProduct("Игровые консоли")}
          >
            Игровые консоли
          </button>
          <button
            className="btn btn-outline-dark btn-sm m-2"
            onClick={() => filterProduct("Игры")}
          >
            Игры
          </button>
          <button
            className="btn btn-outline-dark btn-sm m-2"
            onClick={() => filterProduct("Игровые аксессуары")}
          >
            Игровые аксессуары
          </button>
          <button
            className="btn btn-outline-dark btn-sm m-2"
            onClick={() => filterProduct("Цифровые товары")}
          >
            Цифровые товары
          </button>
        </div>

        {filter.length === 0 ? (
          <div className="no-products-message">No products available</div>
        ) : (
          <div className="product-card-container">
            {filter.map((product) => (
              <div key={product._id} className="product-card">
                {/* Карточки продуктов */}
                <div className="slider-image-wrapper">
                  <img
                    className="slider-image"
                    src={product.image}
                    alt={product.title}
                    onClick={() => handleShowModal(product)}
                  />
                </div>
                <div className="product-card-content">
                  <h5
                    className="product-card-title"
                    onClick={() => handleShowModal(product)}
                  >
                    {product.title.substring(0, 30)}...
                  </h5>
                  <p className="product-description">
                    {product.description.substring(0, 140)}...
                  </p>
                  <div className="product-price-container">
                    <span className="product-price-chip">
                      {product.price} ₽
                    </span>
                  </div>
                  <div className="product-availability">
                    {product.stock > 0 ? (
                      <span className="available">
                        В наличии: {product.stock} шт.
                      </span>
                    ) : (
                      <span className="unavailable">Нет в наличии</span>
                    )}
                  </div>
                  <div className="product-actions d-flex">
                    <Link
                      to={`/product/${product._id}`}
                      className="btn btn-dark flex-grow-1 m-1"
                    >
                      Купить
                    </Link>
                    <button
                      className="btn btn-primary flex-grow-1 m-1 add-to-cart-button"
                      onClick={() => addProduct(product)}
                      disabled={product.stock === 0}
                    >
                      В корзину
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        {/* Модальное окно */}
        <ProductModal
          show={showModal}
          handleClose={handleCloseModal}
          product={selectedProduct}
        />
      </>
    );
  };

  return (
    <>
      <div className="container my-3 py-3">
        <div className="row">
          <div className="col-12">
            <h2 className="display-5 text-center">Последние товары</h2>
            <hr />
          </div>
        </div>
        <div className="row justify-content-center">
          {loading ? <Loading /> : <ShowProducts />}
        </div>
      </div>
    </>
  );
};

export default Products;
